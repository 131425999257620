
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import EmpowerInfo from '@/components/empower-info/empower-info.vue'
import EmpowerInfoItem from '@/components/empower-info-item/empower-info-item.vue'
import { ElMessage } from 'element-plus'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    EmpowerInfo,
    EmpowerInfoItem
  }
})
export default class result_empower extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  users_dialog = {
    open: false,
    title: '请选择',
    users: [] as any[],
    user_ids: [] as number[],
    type: 1, // 1: 成果完成人, 2: 权属内部分配
    is_edit: false // 是否为编辑窗
  }

  table1_all_data = []

  table1_total = 0
  table1_search_data = {
    name: '',
    start_time: '',
    end_time: ''
  }

  rule_table1_search_data = {}

  table1_add_dialog = {
    open: false,
    title: '新增成果'
  }

  table1_add_data = {
    // 2021-11-08 新增
    deadline_other: '', // 其他长期使用期限
    source_other: '', // 其他成果来源
    distribution: [
      {
        id: 0,
        name: '',
        avatar: '',
        dept: '',
        id_number: '',
        file: [],
        duty: '',
        scale: '',
        type: 2
      }
    ] as any[], // 权属内部分配情况
    obligee: '广东工业大学', // 权利人
    rests_type: '', // 其他成果类型
    confer: '', // 之前成果转化或已有商谈情况

    name: '',
    empower_agreement: [] as any[],
    empower_way: 1,
    live_time: '',
    result_type: 1,
    finish_person: [
      {
        id: 0,
        name: '',
        avatar: '',
        dept: '',
        id_number: '',
        file: [],
        duty: '',
        scale: '',
        type: 2
      }
    ] as any[],
    college: '',
    is_level: 0,
    agent: '',
    agent_phone: '',
    team_name: '',
    member: '',
    signtory: '',
    source: 0,
    naturn: 0,
    is_topic: 0,
    topic_time: '',
    detaild_list: [{ name: '', file: [] }] as any[],
    deputy: '',
    sign_phone: '',
    application_area: '',
    target_customer: '',
    rests_state: '',
    way: [],
    rests_way: '',
    expected_price: 0,
    reserve_price: 0,
    brief: '',
    technical_maturity: 0,
    economic: '',
    society: '',
    risk: ''
  }
  table1_add_data_copy = {
    // 2021-11-08 新增
    deadline_other: '', // 其他长期使用期限
    source_other: '', // 其他成果来源
    distribution: [
      {
        id: 0,
        name: '',
        avatar: '',
        dept: '',
        id_number: '',
        file: [],
        duty: '',
        scale: '',
        type: 2
      }
    ] as any[], // 权属内部分配情况
    obligee: '广东工业大学', // 权利人
    rests_type: '', // 其他成果类型
    confer: '', // 之前成果转化或已有商谈情况

    name: '',
    empower_agreement: [] as any[],
    empower_way: 1,
    live_time: '',
    result_type: 1,
    finish_person: [
      {
        id: 0,
        name: '',
        avatar: '',
        dept: '',
        id_number: '',
        file: [],
        duty: '',
        scale: '',
        type: 2
      }
    ] as any[],
    college: '',
    is_level: 0,
    agent: '',
    agent_phone: '',
    team_name: '',
    member: '',
    signtory: '',
    source: 0,
    naturn: 0,
    is_topic: 0,
    topic_time: '',
    detaild_list: [{ name: '', file: [] }] as any[],
    deputy: '',
    sign_phone: '',
    application_area: '',
    target_customer: '',
    rests_state: '',
    way: [],
    rests_way: '',
    expected_price: 0,
    reserve_price: 0,
    brief: '',
    technical_maturity: 0,
    economic: '',
    society: '',
    risk: ''
  }

  rule_table1_add_data = {
    name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    empower_way: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    result_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    college: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    is_level: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    agent: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    agent_phone: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    team_name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    member: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    signtory: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    source: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    naturn: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    is_topic: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    deputy: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    sign_phone: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }]
  }

  table1_detail_dialog = {
    open: false,
    title: '详情'
  }

  table1_detail_data = {
    deadline_other: '', // 其他长期使用期限
    source_other: '', // 其他成果来源
    distribution: [] as any[], // 权属内部分配情况
    obligee: '广东工业大学', // 权利人
    rests_type: '', // 其他成果类型
    confer: '', // 之前成果转化或已有商谈情况
    college_name: '',

    name: '',
    created_at: '',
    code: '',
    empower_agreement: [] as any[],
    empower_way: 0,
    live_time: '',
    result_type: 0,
    finish_person: [] as any[],
    college: '',
    is_level: 0,
    agent: '',
    agent_phone: '',
    team_name: '',
    member: '',
    signtory: '',
    source: 0,
    naturn: 0,
    is_topic: 0,
    topic_time: '',
    detaild_list: [] as any[],
    deputy: '',
    sign_phone: '',
    application_area: '',
    target_customer: '',
    rests_state: '',
    way: [],
    rests_way: '',
    expected_price: 0,
    reserve_price: 0,
    brief: '',
    technical_maturity: 0,
    economic: '',
    society: '',
    risk: ''
  }

  table1_edit_dialog = {
    open: false,
    title: '编辑成果'
  }

  table1_edit_data = {
    deadline_other: '', // 其他长期使用期限
    source_other: '', // 其他成果来源
    distribution: [] as any[], // 权属内部分配情况
    obligee: '广东工业大学', // 权利人
    rests_type: '', // 其他成果类型
    confer: '', // 之前成果转化或已有商谈情况

    name: '',
    id: 0,
    empower_agreement: [],
    empower_way: 0,
    live_time: '',
    result_type: 0,
    finish_person: [] as any[],
    college: '',
    is_level: 0,
    agent: '',
    agent_phone: '',
    team_name: '',
    member: '',
    signtory: '',
    source: 0,
    naturn: 0,
    is_topic: 0,
    topic_time: '',
    detaild_list: [] as any[],
    deputy: '',
    sign_phone: '',
    application_area: '',
    target_customer: '',
    rests_state: '',
    way: [],
    rests_way: '',
    expected_price: 0,
    reserve_price: 0,
    brief: '',
    technical_maturity: 0,
    economic: '',
    society: '',
    risk: ''
  }

  rule_table1_edit_data = {
    name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    empower_way: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    result_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    college: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    is_level: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    agent: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    agent_phone: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    team_name: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    member: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    signtory: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    source: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    naturn: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    is_topic: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
    deputy: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }],
    sign_phone: [{ required: true, messgae: '此项不能为空', trigger: 'blur' }]
  }

  table1_edit1_dialog = {
    open: false,
    title: '提交'
  }

  table1_edit1_data = {
    id: 0
  }

  rule_table1_edit1_data = {}

  judge_opinons_0001 = new Map<any, string>([[0, '草稿']])

  empower_way_959 = new Map<any, string>([
    [1, '所有权'],
    [2, '长期使用权']
  ])
  status_960 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派科研院审核员'],
    [6, '待科研院审核'],
    [7, '待指派产研院审核员'],
    [8, '待产研院审核'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示审核'],
    [11, '待指派合同评审员'],
    [12, '待合同评审'],
    [13, '待指派权属办合同审批人'],
    [14, '待权属办合同审批'],
    [15, '待指派权属委合同审批人'],
    [16, '待权属委合同审批'],
    [17, '待指派权属委负责人'],
    [18, '待权属委负责人审批'],
    [19, '待指派校章专员'],
    [20, '待盖校章'],
    [21, '审核通过'],
    [22, '待指派权属办异常审核员'],
    [23, '待权属办异常审核'],
    [24, '待指派权属委异常复审员'],
    [25, '待权属委异常复审']
  ])
  result_type_962 = new Map<any, string>([
    [1, '专利'],
    [2, '计算机软件'],
    [3, '集成电路布图设计'],
    [4, '生物医药新品种'],
    [5, '技术秘密'],
    [6, '其他']
  ])
  is_level_970 = new Map<any, string>([
    [0, '正处级以下'],
    [1, '正处级或院士'],
    [2, '副厅级及以上']
  ])
  source_976 = new Map<any, string>([
    [1, '中央和地方财政资金项目'],
    [2, '企业、其他社会组织委托项目'],
    [3, '其他']
  ])
  naturn_977 = new Map<any, string>([
    [0, '否'],
    [1, '是']
  ])
  is_topic_978 = new Map<any, string>([
    [1, '是'],
    [0, '否']
  ])
  technical_maturity_993 = new Map<any, string>([
    [1, '报告级'],
    [2, '方案级'],
    [3, '功能级'],
    [4, '仿真级'],
    [5, '初样级'],
    [6, '正样级'],
    [7, '环境级'],
    [8, '产品级'],
    [9, '系统级'],
    [10, '销售级/应用级']
  ])

  depts = [] as any[]

  addPerson(type: number, is_edit: boolean) {
    this.users_dialog.type = type
    this.users_dialog.is_edit = is_edit
    this.users_dialog.open = true
    this.users_dialog.users = [] as any[]
    this.users_dialog.user_ids = [] as number[]
    // 添加
    if (!is_edit) {
      if (type === 1) {
        this.table1_add_data.finish_person.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
      if (type === 2) {
        this.table1_add_data.distribution.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
    }
    // 编辑
    if (is_edit) {
      if (type === 1) {
        this.table1_edit_data.finish_person.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
      if (type === 2) {
        this.table1_edit_data.distribution.forEach((item) => {
          if (item.type === 1) {
            this.users_dialog.user_ids.push(item.id)
            this.users_dialog.users.push(item)
          }
        })
      }
    }
  }

  users_dialog_ok() {
    // 添加
    if (!this.users_dialog.is_edit) {
      if (this.users_dialog.type === 1) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            file: [],
            duty: '',
            scale: '',
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.dept = item.dept
          temp.duty = item.duty
          const index = this.table1_add_data.finish_person.findIndex(
            (items) => {
              return items.id === temp.id
            }
          )
          if (index < 0) this.table1_add_data.finish_person.push(temp)
        })
        this.users_dialog.open = false
      }
      if (this.users_dialog.type === 2) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            file: [],
            duty: '',
            scale: '',
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.duty = item.duty
          const index = this.table1_add_data.distribution.findIndex((items) => {
            return items.id === temp.id
          })
          if (index < 0) this.table1_add_data.distribution.push(temp)
        })
        this.users_dialog.open = false
      }
    }
    // 编辑
    if (!this.users_dialog.is_edit) {
      if (this.users_dialog.type === 1) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            file: [],
            duty: '',
            scale: 0,
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.dept = item.dept
          temp.duty = item.duty
          const index = this.table1_edit_data.finish_person.findIndex(
            (items) => {
              return items.id === temp.id
            }
          )
          if (index < 0) this.table1_edit_data.finish_person.push(temp)
        })
        this.users_dialog.open = false
      }
      if (this.users_dialog.type === 2) {
        this.users_dialog.users.forEach((item) => {
          const temp = {
            id: 0,
            name: '',
            avatar: '',
            dept: '',
            id_number: '',
            file: [],
            duty: '',
            scale: '',
            type: 1
          }
          temp.id = item.id
          temp.name = item.name
          temp.avatar = item.avatar
          temp.id_number = item.id_number
          temp.duty = item.duty
          const index = this.table1_edit_data.distribution.findIndex(
            (items) => {
              return items.id === temp.id
            }
          )
          if (index < 0) this.table1_edit_data.distribution.push(temp)
        })
        this.users_dialog.open = false
      }
    }
  }

  users_dialog_cancle() {
    this.users_dialog.open = false
  }

  // 获取所在学院
  getDepts() {
    Api.http_userusersdepts0({ offset: 0, length: 999 }).then((res) => {
      if (res.code === '200') {
        this.depts = res.data.data
      } else {
        this.$message.error(res.message)
      }
    })
  }

  table1_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      name: this.table1_search_data.name,
      start_time: this.table1_search_data.start_time,
      end_time: this.table1_search_data.end_time
    }
    Api.http_result_empowertable10(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_all_data = res.data.data
        this.table1_total = res.data.total
      }
    })
  }
  table1_add_init(row) {
    this.table1_add_dialog.open = true
    this.getDepts()
    this.setDialogName(this.table1_add_dialog.title)
  }
  table1_add_cancle() {
    this.table1_add_dialog.open = false
    this.table1_add_data = this.table1_add_data_copy
    this.removeDialogName()
  }
  table1_add_ok() {
    this.$refs.ref_table1_add_data.validate((valid) => {
      if (valid) {
        let ratio = 0
        this.table1_add_data.distribution.forEach(item => {
          ratio += Number(item.scale)
        })
        if (ratio !== 100) return ElMessage.error('权属内部分配比例之和必须为100%')
        const postData = {
          // 2021.11.08 新增
          deadline_other: this.table1_add_data.deadline_other,
          source_other: this.table1_add_data.source_other,
          distribution: this.table1_add_data.distribution,
          obligee: this.table1_add_data.obligee,
          rests_type: this.table1_add_data.rests_type,
          confer: this.table1_add_data.confer,

          name: this.table1_add_data.name,
          empower_agreement: this.table1_add_data.empower_agreement,
          empower_way: this.table1_add_data.empower_way,
          live_time: this.table1_add_data.live_time,
          result_type: this.table1_add_data.result_type,
          finish_person: this.table1_add_data.finish_person,
          college: this.table1_add_data.college,
          is_level: this.table1_add_data.is_level,
          agent: this.table1_add_data.agent,
          agent_phone: this.table1_add_data.agent_phone,
          team_name: this.table1_add_data.team_name,
          member: this.table1_add_data.member,
          signtory: this.table1_add_data.signtory,
          source: this.table1_add_data.source,
          naturn: this.table1_add_data.naturn,
          is_topic: this.table1_add_data.is_topic,
          topic_time: this.table1_add_data.topic_time,
          detaild_list: this.table1_add_data.detaild_list,
          deputy: this.table1_add_data.deputy,
          sign_phone: this.table1_add_data.sign_phone,
          application_area: this.table1_add_data.application_area,
          target_customer: this.table1_add_data.target_customer,
          rests_state: this.table1_add_data.rests_state,
          way: this.table1_add_data.way,
          rests_way: this.table1_add_data.rests_way,
          expected_price: this.table1_add_data.expected_price,
          reserve_price: this.table1_add_data.reserve_price,
          brief: this.table1_add_data.brief,
          technical_maturity: this.table1_add_data.technical_maturity,
          economic: this.table1_add_data.economic,
          society: this.table1_add_data.society,
          risk: this.table1_add_data.risk
        }
        Api.http_result_empowertable11(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table1_add_dialog.open = false
            this.removeDialogName()
            this.table1_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
            this.table1_add_data = this.table1_add_data_copy
          }
        })
      }
    })
  }
  table1_detail_init(row) {
    Api.http_result_empowertable1get4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        // 2021.11.08 新增
        this.table1_detail_data.deadline_other = res.data.deadline_other
        this.table1_detail_data.source_other = res.data.source_other
        this.table1_detail_data.distribution = res.data.distribution
        this.table1_detail_data.obligee = res.data.obligee
        this.table1_detail_data.rests_type = res.data.rests_type
        this.table1_detail_data.confer = res.data.confer
        this.table1_detail_data.college_name = res.data.college_name

        this.table1_detail_data.name = res.data.name
        this.table1_detail_data.created_at = res.data.created_at
        this.table1_detail_data.code = res.data.code
        this.table1_detail_data.empower_agreement = res.data.empower_agreement
        this.table1_detail_data.empower_way = res.data.empower_way
        this.table1_detail_data.live_time = res.data.live_time
        this.table1_detail_data.result_type = res.data.result_type
        this.table1_detail_data.finish_person = res.data.finish_person
        this.table1_detail_data.college = res.data.college
        this.table1_detail_data.is_level = res.data.is_level
        this.table1_detail_data.agent = res.data.agent
        this.table1_detail_data.agent_phone = res.data.agent_phone
        this.table1_detail_data.team_name = res.data.team_name
        this.table1_detail_data.member = res.data.member
        this.table1_detail_data.signtory = res.data.signtory
        this.table1_detail_data.source = res.data.source
        this.table1_detail_data.naturn = res.data.naturn
        this.table1_detail_data.is_topic = res.data.is_topic
        this.table1_detail_data.topic_time = res.data.topic_time
        this.table1_detail_data.detaild_list = res.data.detaild_list
        this.table1_detail_data.deputy = res.data.deputy
        this.table1_detail_data.sign_phone = res.data.sign_phone
        this.table1_detail_data.application_area = res.data.application_area
        this.table1_detail_data.target_customer = res.data.target_customer
        this.table1_detail_data.rests_state = res.data.rests_state
        this.table1_detail_data.way = res.data.way
        this.table1_detail_data.rests_way = res.data.rests_way
        this.table1_detail_data.expected_price = res.data.expected_price
        this.table1_detail_data.reserve_price = res.data.reserve_price
        this.table1_detail_data.brief = res.data.brief
        this.table1_detail_data.technical_maturity = res.data.technical_maturity
        this.table1_detail_data.economic = res.data.economic
        this.table1_detail_data.society = res.data.society
        this.table1_detail_data.risk = res.data.risk
        this.table1_detail_dialog.open = true
        this.setDialogName(this.table1_detail_dialog.title)
      }
    })
  }
  table1_detail_cancle() {
    this.table1_detail_dialog.open = false
    this.removeDialogName()
  }
  table1_detail_ok() {
    this.table1_detail_dialog.open = false
    this.removeDialogName()
  }
  table1_edit_init(row) {
    Api.http_result_empowertable1get5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_edit_data.id = row.id
        // 2021.11.08 新增
        this.table1_edit_data.deadline_other = res.data.deadline_other
        this.table1_edit_data.source_other = res.data.source_other
        this.table1_edit_data.distribution = res.data.distribution
        this.table1_edit_data.obligee = res.data.obligee
        this.table1_edit_data.rests_type = res.data.rests_type
        this.table1_edit_data.confer = res.data.confer

        this.table1_edit_data.name = res.data.name
        this.table1_edit_data.empower_agreement = res.data.empower_agreement
        this.table1_edit_data.empower_way = res.data.empower_way
        this.table1_edit_data.live_time = res.data.live_time
        this.table1_edit_data.result_type = res.data.result_type
        this.table1_edit_data.finish_person = res.data.finish_person
        this.table1_edit_data.college = res.data.college
        this.table1_edit_data.is_level = res.data.is_level
        this.table1_edit_data.agent = res.data.agent
        this.table1_edit_data.agent_phone = res.data.agent_phone
        this.table1_edit_data.team_name = res.data.team_name
        this.table1_edit_data.member = res.data.member
        this.table1_edit_data.signtory = res.data.signtory
        this.table1_edit_data.source = res.data.source
        this.table1_edit_data.naturn = res.data.naturn
        this.table1_edit_data.is_topic = res.data.is_topic
        this.table1_edit_data.topic_time = res.data.topic_time
        this.table1_edit_data.detaild_list = res.data.detaild_list
        this.table1_edit_data.deputy = res.data.deputy
        this.table1_edit_data.sign_phone = res.data.sign_phone
        this.table1_edit_data.application_area = res.data.application_area
        this.table1_edit_data.target_customer = res.data.target_customer
        this.table1_edit_data.rests_state = res.data.rests_state
        this.table1_edit_data.way = res.data.way
        this.table1_edit_data.rests_way = res.data.rests_way
        this.table1_edit_data.expected_price = res.data.expected_price
        this.table1_edit_data.reserve_price = res.data.reserve_price
        this.table1_edit_data.brief = res.data.brief
        this.table1_edit_data.technical_maturity = res.data.technical_maturity
        this.table1_edit_data.economic = res.data.economic
        this.table1_edit_data.society = res.data.society
        this.table1_edit_data.risk = res.data.risk
        this.table1_edit_dialog.open = true
        this.setDialogName(this.table1_edit_dialog.title)
        this.getDepts()
      }
    })
  }
  table1_edit_cancle() {
    this.table1_edit_dialog.open = false
    this.removeDialogName()
  }
  table1_edit_ok() {
    this.$refs.ref_table1_edit_data.validate((valid) => {
      if (valid) {
        let ratio = 0
        this.table1_edit_data.distribution.forEach(item => {
          ratio += Number(item.scale)
        })
        if (ratio !== 100) return ElMessage.error('权属内部分配比例之和必须为100%')
        const postData = {
          // 2021-11-09 新增
          deadline_other: this.table1_edit_data.deadline_other,
          source_other: this.table1_edit_data.source_other,
          distribution: this.table1_edit_data.distribution,
          obligee: this.table1_edit_data.obligee,
          rests_type: this.table1_edit_data.rests_type,
          confer: this.table1_edit_data.confer,

          name: this.table1_edit_data.name,
          id: this.table1_edit_data.id,
          empower_agreement: this.table1_edit_data.empower_agreement,
          empower_way: this.table1_edit_data.empower_way,
          live_time: this.table1_edit_data.live_time,
          result_type: this.table1_edit_data.result_type,
          finish_person: this.table1_edit_data.finish_person,
          college:
            typeof this.table1_edit_data.college === 'number'
              ? [this.table1_edit_data.college]
              : this.table1_edit_data.college,
          is_level: this.table1_edit_data.is_level,
          agent: this.table1_edit_data.agent,
          agent_phone: this.table1_edit_data.agent_phone,
          team_name: this.table1_edit_data.team_name,
          member: this.table1_edit_data.member,
          signtory: this.table1_edit_data.signtory,
          source: this.table1_edit_data.source,
          naturn: this.table1_edit_data.naturn,
          is_topic: this.table1_edit_data.is_topic,
          topic_time: this.table1_edit_data.topic_time,
          detaild_list: this.table1_edit_data.detaild_list,
          deputy: this.table1_edit_data.deputy,
          sign_phone: this.table1_edit_data.sign_phone,
          application_area: this.table1_edit_data.application_area,
          target_customer: this.table1_edit_data.target_customer,
          rests_state: this.table1_edit_data.rests_state,
          way: this.table1_edit_data.way,
          rests_way: this.table1_edit_data.rests_way,
          expected_price: this.table1_edit_data.expected_price,
          reserve_price: this.table1_edit_data.reserve_price,
          brief: this.table1_edit_data.brief,
          technical_maturity: this.table1_edit_data.technical_maturity,
          economic: this.table1_edit_data.economic,
          society: this.table1_edit_data.society,
          risk: this.table1_edit_data.risk
        }
        Api.http_result_empowertable15(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table1_edit_dialog.open = false
            this.removeDialogName()
            this.table1_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  table1_edit1_init(row) {
    Api.http_result_empowertable1get6({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.table1_edit1_data.id = row.id
        this.table1_edit1_dialog.open = true
      }
    })
  }
  table1_edit1_cancle() {
    this.table1_edit1_dialog.open = false
  }
  table1_edit1_ok() {
    this.$refs.ref_table1_edit1_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.table1_edit1_data.id
        }
        Api.http_result_empowertable16(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.table1_edit1_dialog.open = false
            this.table1_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  table1_init() {
    this.table1_get_all_data(0, 10)
  }
  created() {
    this.table1_init()
  }
}
